﻿$color-purple: #7D138F;
$color-darkpurple: #1F1C52;
$color-lighteraquablue: #AEE6EB;
$color-lightaquablue: #88C5D6;
$color-aquablue: #0C77AC;
$color-darkaquablue: #084E79;
$color-darkeraquablue: #052F46;
$color-lightergold: #FFEEBF;
$color-lightgold: #EDCA69;
$color-gold: #DBAB27;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray: #999999;

$color-green: #65D52A;
$color-yellow: #EDCA69;
$color-orange: #FF8900;