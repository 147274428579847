﻿body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
img{
    width:100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
    margin: 0 auto;
    padding-top: 4rem;
}

@media screen and (max-width: 63.9375em) {
    .content {
        max-width: 100%;
        margin: 0 auto;
    }
}

.content-container {
    min-height: 100%;
    margin-left: 5rem;
    margin-right: 5rem;
    padding-bottom: 6rem;
    margin-bottom: -4rem;

    &:after {
        content: "";
        display: block;
    }

    @include breakpoint(small only){ 
        margin-left: 0;
        margin-right: 0;
      }
}


.content-block {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include breakpoint(small only){ 
        padding-left: 1rem;
        padding-right: 1rem;
      }
}

.content-header {
    margin-bottom:0;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0.5rem;
    background-color: $color-darkpurple;
    color: $color-lightergold;
    text-align:center;
    font-weight:bold;

    @include breakpoint(small only){ 
        padding-top: 2rem;
        margin-left: 0;
        margin-right: 0;
      }
}

.content-breadcrumbs {
    background-color: $color-aquablue;
    color: $color-white;
    font-size:0.8em;
    width: 100%
}

.content-description {
    background-color: $color-white;
    color: $color-black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 15%;
    padding-right: 15%;
    width:100%;

    @include breakpoint(small only){ 
        padding-left: 1rem;
        padding-right: 1rem;
      }
    
}

.content-image-container{
    float:right;
    width:25%;

    @include breakpoint(small only){ 
       float:none;
       width:100%;
      }
}

.content-image{
    margin:1rem;
    border-radius: 5px;
    border: 1px solid $color-aquablue;
    min-width:8rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;

    a {
        display:block;
        width:100%;
        &:hover {
            cursor: pointer;
        }

        img{
            padding-top:1rem;
            padding-bottom:1rem;
            margin-left:auto;
            margin-right:auto;
            display:block;
            max-width:80%;
        }
    }
}

.comments {
    margin: 1rem 0;
}

.reflectionResp {
    background-color: white;
    color:black;
    padding: 5%;
    word-wrap: break-word;

}

.content-questionnaire {
    background-color: $color-aquablue;
    color: $color-white;
    width:100%;

    p{
        text-align:left;
        font-weight:normal;
    }

    h3{
        padding-top:1rem;
        padding-bottom:1rem;
        &.title{
            text-align:center;
            padding-left:10rem;
            padding-right:10rem;
        }
    }
}

.right {
    float: right;
}

.left {
    float: left;
}

.clearfix {
    &::before,
    &::after {
        display: table;
        content: ' ';
    }

    &::after {
        clear: both;
    }
}

.button {
    font-weight: bold;
    border-radius: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: .85em 1em;
    border: 2px solid transparent;
    transition: background-color .25s ease-out,color .25s ease-out;
    font-family: inherit;
    font-size: 1rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    text-decoration: none !important;
}

.save-button {
    background-color: $color-lightaquablue;
    color: $color-black;
    border-color: $color-darkpurple;
    text-decoration: none;
    min-width:6rem;
    &:hover {
        background-color: $color-lighteraquablue;
        color: $color-black;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: $color-aquablue;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
    @include breakpoint(small only){ 
        width:100%;
    }
}

.textfieldLabel, .dropdownfieldLabel {
    color: $color-white;
    text-align:right;
    padding-left:1rem;
    padding-right:1rem;

    @include breakpoint(small only){ 
        text-align:center;
      }
}

.checkboxfieldLabel {
    color: $color-white;
    padding: 1rem 0;
    span {
        display: inline-block;
        vertical-align: text-bottom;
    }

    @include breakpoint(small only){ 
        text-align:center;
      }
}

.textfield, .dropdownfield {
    min-height: 80%;
}

.checkboxfield {
    -webkit-appearance:none;
    width:30px;
    height:30px;
    background:$color-white;
    border-radius:5px;
    border:2px solid $color-black;
    margin-bottom: 0;
}

.checkboxfield:checked {
    background: $color-green;
}

.textarea-wrapper {
    height: 15rem;
    margin-top: 20px;
}

.textarea {
    min-height: 90%;
}
.reflectionResp {
    font-size: 0.9rem;
    margin-left: 10px;
}

.question-container {
    border-radius: 5px;
    display: block;
    margin: 2rem 0 2rem 0;
    padding: .85rem 1rem;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    color: $color-black;

    &.gold {
        background-color: $color-gold;
    }

    &.lightgold {
        background-color: $color-lightgold;
    }

    &.lightergold {
        background-color: $color-lightergold;
    }

    &.lightaquablue{
        background-color: $color-lightaquablue;
    }

    .question-text{
        font-weight:bold;
        text-align:center;
    }

    ul, ol{
        text-align:left;
        font-weight:normal;
    }
}

.learning-question-context {
    text-align: center !important;
    font-size: .8rem;
    a {
        color: $color-black;
        text-decoration: underline;
    }
}

.response-container {
    border-radius: 10px;
    padding: 0;
    vertical-align: middle;
    margin: 1rem 0;
    border: 2px solid $color-black;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    color: $color-black;
    background-color: $color-lightaquablue;

    .question-text{
        font-weight:bold;
        text-align:center;
    }

    ul, ol{
        text-align:left;
        font-weight:normal;
    }

    .response-cell{
        border-top:0 solid $color-black;
        border-bottom:0 solid $color-black;
        border-left:1px solid $color-black;
        border-right:1px solid $color-black;

        &:hover {
            background-color: $color-lighteraquablue;
        }
        &.selected {
            background-color: $color-green;
        }



        &:first-child{
            border-left:0;

            &:hover, 
            &.selected{
                border-top-left-radius: 10px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child{
            border-right:0;

            &:hover, 
            &.selected{
                border-top-left-radius: 0;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 10px;
            }
        }

        button{
            display:block;
            width:100%;
            height: 100%;
            padding:0.5rem;
            cursor: pointer;

            .response-text-container {
                height: 100%;

                p{
                    text-align:center;
                    margin-bottom: 0.25rem;

                    &.big-number{
                        font-weight: bold;
                        font-size: 1.5rem;
                    }
                }
            }
        }

        @include breakpoint(small only){ 
            border-top:1px solid $color-black;
            border-bottom:1px solid $color-black;
            border-left:0 solid $color-black;
            border-right:0 solid $color-black;

            &:first-child{
                border-top:0;
    
                &:hover, 
                &.selected{
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
    
            &:last-child{
                border-bottom:0;
    
                &:hover, 
                &.selected{
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
          }
    }
}

.content-navigation-buttons
{
    margin-bottom:5rem;
    margin-left:5rem;
    margin-right:5rem;
    .next-button{
        float:right;
    }

    .back-button{
        float:left;
    }

    @include breakpoint(small only){ 
        padding-top:0;
        margin-bottom:0;
        margin-left:0;
        margin-right:0;

        .next-button{
            float:none;
            width:100%;
        }
    
        .back-button{
            float:none;
            width:100%;
        }
    }
}

.question-button {
    background-color: $color-lightaquablue;
    color: $color-black;
    border-color: $color-darkpurple;
    text-decoration: none;

    &:hover {
        background-color: $color-lighteraquablue;
        color: $color-black;
        border-color: $color-darkpurple;
    }
}

.next-button{
    background-color: $color-aquablue;
    color: $color-white;
    border-color: $color-white;
    text-decoration: none;
    min-width:6rem;
    &:hover {
        background-color: $color-darkeraquablue;
        color: $color-white;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: $color-aquablue;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}

.back-button{
    background-color: transparent;
    color: $color-white;
    border-color: $color-white;
    text-decoration: none;
    min-width:6rem;
    &:hover {
        background-color: $color-darkeraquablue;
        color: $color-white;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: transparent;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: transparent;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: transparent;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}

.score-table{
    border:1px solid $color-white;
    margin-top:2rem;
    margin-bottom:2rem;
}

.score-table-cell{
    border:1px solid $color-white;

    p{
        margin:0.75rem;
        text-align:center;
    }
}

.score-cell{
    max-width:150px;
}

.score-box, .score-description-cell {
    margin: 0.4rem 0.5rem;

    h3 {
        margin-top: 0;
        padding-top: 0;
    }
}

.score-box{
    border-radius: 10px;
    display: inline-block;
    padding: 0.2rem 0.4rem;
    border: 2px solid $color-black;
    font-family: inherit;
    font-size: 1.60rem;
    font-weight: bold;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    color: $color-black;
}

.score-lightblue{
    background-color: $color-lightaquablue;
    color: $color-black;
}

.score-green{
    background-color: $color-green;
    color: $color-black;
}

.score-yellow{
    background-color: $color-yellow;
    color: $color-black;
}

.score-orange{
    background-color: $color-orange;
    color: $color-black;
}

.score-gray {
    background-color: $color-gray;
    color: $color-black;
}

.wide-action-button {
    width:90%;
    max-width:600px;
    display:block;
    margin-left:auto;
    margin-right:auto;
    white-space: normal;

    &:hover {
        background-color: $color-lighteraquablue;
        color: $color-black;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: $color-aquablue;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}

.small-action-button {
    width: 90%;
    max-width: 150px;
    display:block;
    white-space: normal;

    &:hover {
        background-color: $color-lighteraquablue;
        color: $color-black;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: $color-aquablue;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}

.light-action-button {
    background-color: $color-lightaquablue;
    color: $color-black;
    border-color: $color-darkpurple;
    text-decoration: none;
    white-space: normal;

    &:hover {
        background-color: $color-lighteraquablue;
        color: $color-black;
        border-color: $color-purple;
    }

    &.disabled{
        opacity:initial;
        background-color: $color-aquablue;
        color: $color-gray;
        border-color: $color-gray;

        &:hover {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }

        &:focus {
            background-color: $color-aquablue;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}

.dark-action-button {
    background-color: $color-aquablue;
    color: $color-white;
    border-color: $color-lightaquablue;
    text-decoration: none;
    white-space: normal;

    &:hover {
        background-color: $color-darkaquablue;
        color: $color-white;
        border-color: $color-lighteraquablue;
    }
}

span.critical-question{
    color: $color-purple;
    text-decoration:underline;
}

.image-caption
{
    font-size:.7rem;
}

.box-shadow {
    box-shadow: 0 2px 4px 0px rgba(128, 128, 128, .4);
}

.text-danger {
    font-weight: bold;
    color: red;
}

.participant-list-container {
    a {
        color: $color-white;

        &:hover {
            text-decoration: underline;
        }
    }

    .icon {
        padding: 0 0.25rem;
        display: inline-block;
    }
}