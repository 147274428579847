﻿html {
    background-color: $color-black;
    font-size: 24px;
}

body {
    font-family: 'Open Sans', sans-serif;
    border-style: none;
    border-width: 0;
    background-image: url("../Images/background.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: $color-black;
    height: 100%;
}

h1, h2, h3, h4, h5, h6, p, label {
    font-family: 'Open Sans', sans-serif;
}

h1{
    font-weight:800;
}

h2, h4 {
    font-weight:400;
}

h1 {
    font-size: 2rem;
    font-weight: bolder;
}

h2 {
    font-size: 1.5rem;
    font-weight: bolder;
}

h3 {
    font-size: 1.25rem;
    font-weight: bold;
}

h4 {
    font-size: 1.1rem;
    font-weight: bold;
}

h5, h6, p {
    font-size: 1rem;
}

label{
    display:inline-block;
    font-weight:bold;
    font-size:1.2rem;
}

select {
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    font-family: 'PT Sans', serif;

    option {
        font-family: 'PT Sans', serif;
    }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    border-radius: 5px;
    font-family: 'PT Sans', sans-serif;
}

.bold{
    font-weight:bold;
}

.text-center{
    text-align:center;
}